import React from 'react'
import HeaderSection from '../HeaderSection'
import { Link } from 'react-router-dom'
import '../Cards.css'
import '../Contact.css'
import ReviewItem from '../ReviewItem'
import { useTranslation } from 'react-i18next'


function Feedback() {
    const {t} = useTranslation();

  return (
    <>
     <HeaderSection
        cName='header-container'
        headerImg='images/BRANDING.jpg'
      />

<div className='cards'>
            <h1>{t('feedback.review')}</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <ReviewItem
                            paragraph= {t('feedback.f1text')}
                            text={t('feedback.f1')}
                            date='January 2023'
                        />
                        <ReviewItem
                            paragraph= {t('feedback.f2text')}
                            text={t('feedback.f1')}
                            date='May 2023'
                        />
                        <ReviewItem
                           paragraph={t('feedback.f5text')}
                           text={t('feedback.f1')}
                           date='August 2023'
                       />
                    </ul>
                    <ul className='cards__items'>
                        <ReviewItem
                           paragraph={t('feedback.f4text')}
                           text={t('feedback.f1')}
                           date='April 2023'
                       />
                  
                         <ReviewItem
                            paragraph={t('feedback.f3text')}
                            text={t('feedback.f1')}
                            date='January 2023'
                        />
                        <ReviewItem
                            paragraph={t('feedback.f8text')}
                            text={t('feedback.f8')}
                            date='July 2023'
                       />

                    </ul>
                    <ul className='cards__items'>
                         <ReviewItem
                            paragraph={t('feedback.f6text')}
                            text= {t('feedback.f1')}
                            date='June 2023'
                        />
                         <ReviewItem
                            paragraph={t('feedback.f7text')}
                            text={t('feedback.f1')}
                            date='March 2023'
                        />
                       <ReviewItem
                            paragraph={t('feedback.f9text')}
                            text={t('feedback.f9')}
                            date='October 2023'
                       />
                       
                    </ul>
                    <ul className='cards__items'>
                         <ReviewItem
                            paragraph={t('feedback.f10text')}
                            text= {t('feedback.f1')}
                            date='October 2024'
                        />
                       
                    </ul>
                   
                </div>
            </div>   
        </div>


    </>
  )
}

export default Feedback
